<template>
  <div>
    <headertop @changeSort="changeSort"></headertop>
    <fenleilist ref="fenleilist"></fenleilist>
    <bottombox></bottombox>

  </div>
</template>

<script>
import headertop from "./components/header.vue";
import fenleilist from "./components/fenleilist.vue";
import bottombox from "./components/bottombox.vue";

export default {
  name: "home",
  components: {
    fenleilist,
    bottombox,
    headertop
  },
  data() {
    return {
      Inv: 1
    };
  },
  created() {
    localStorage.setItem("loginType", 2);
  },
  mounted() {
    if (this._isMobile()) {
      window.location.href = `${this.GLOBAL.baseUrl}/h5/#/pages/index/index`
    } 
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    },
    changeSort(e) {
      this.$refs.fenleilist.changeSort(e)
    }
  },
};
</script>


<style lang="scss" scoped></style>