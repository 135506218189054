import { render, staticRenderFns } from "./information.vue?vue&type=template&id=5a9e02a1&scoped=true"
import script from "./information.vue?vue&type=script&lang=js"
export * from "./information.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a9e02a1",
  null
  
)

export default component.exports